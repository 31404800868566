<template>
  <v-container fluid>
    <v-layout row style="margin: 0px !important">
      <v-flex xs12 sm12 md12 style="padding: 5px">
        <!-- <v-card elevation="0">
          <v-card-text style="padding: 6px">
            <v-breadcrumbs :items="['Dashboard', 'Settings', 'Templates']">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item style="font-size: 12px">
                  {{ item }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-card-text>
        </v-card> -->
        <Breadcrumbs />
      </v-flex>
      <v-flex xs12 sm12 md12 style="padding: 5px">
        <v-card class="rounded">
          <v-card-title>
            Templates
            <v-spacer></v-spacer>
            <div>
              <v-btn
                class="ma-2"
                dark
                color="primarygrad"
                label
                :to="{ path: `/templates/add` }"
              >
                <v-icon left>
                  add
                </v-icon>
                Add a Template
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <div
              v-if="preloader"
              style="text-align: center; padding: 25px"
              align="center"
            >
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col class="subtitle-1 text-center" cols="12">
                  Please Wait
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    color="primary"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </div>
            <div v-else-if="templates.length > 0">
              <v-list two-line>
                <v-list-item
                  v-for="(item, i) in templates"
                  :key="i"
                  :to="{ path: `/templates/${item.id}` }"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="item.desc"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon color="blue" style="text-transform:none;">
                      {{ item.isLink || item.type == "link" ? "Link" : "" }}
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </div>
          </v-card-text>
          <!-- <v-card-text style="padding: 0px 10px">
            <div>
              <v-list flat subheader three-line>
                <v-subheader>Filter</v-subheader>
                <v-list-item>
                  <template>
                    <v-list-item-action>
                      <v-checkbox
                        color="green"
                        @change="filterByLink($event)"
                      ></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>Link</v-list-item-title>
                      <v-list-item-subtitle
                        >Check if the template is created as
                        link</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list>
            </div>
          </v-card-text> -->
        </v-card>
      </v-flex>
      <!-- <v-flex xs12 sm12 md12 style="padding: 5px">
        <v-card elevation="0">
          !-- <v-card-title>
            Templates
          </v-card-title> --
          
        </v-card>
      </v-flex> -->
    </v-layout>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/breadcrumbs.vue";

export default {
  data() {
    return {
      templates: [],
      allTemplates: [],
      preloader: false,
    };
  },
  components: {
    Breadcrumbs,
  },
  computed: {},
  created() {
    this.getTemplates();
  },
  methods: {
    filterByLink(active) {
      if (active) {
        this.templates = _.filter(this.allTemplates, (t) => {
          return t.isLink;
        });
        return;
      }

      this.templates = this.allTemplates;
    },
    getTemplates() {
      this.preloader = true;
      this.templates = [];
      this.$http
        .get(
          this.$store.state.ENDPOINT +
            "/templates?filter[where][companyId]=" +
            this.$store.getters.user.companyId +
            "&filter[where][templateType][neq]=Whatsapp"
        )
        .then(
          (response) => {
            response = response.body;
            this.allTemplates = response;
            this.templates = this.allTemplates;
            this.preloader = false;
          },
          (error) => {
            this.preloader = false;
            this.$swal({
              type: "error",
              text: "Something went wrong while loading the templates.",
            });
          }
        );
    },
  },
};
</script>

<style scoped></style>
